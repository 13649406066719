import '../index.css'

function Process() {
    return (
        <div className='relative md:px-4 bg-gradient-to-b from-[#131724] to-[#0a0a16] flex flex-col items-center md:items-center md:justify-center pt-20 pb-20' id='process'>
            
            <div className='mx-auto text-center flex flex-col pb-6 pt-4'>
                <h1 className='m-2 md:text-5xl sm:text-4xl text-3xl font-bold md:py-2 text-indigo-300 antialiased'>Discover Our Process</h1>
                <p className='m-2 lg:text-xlg md:text-lg sm:text-base text-sm text-white'>We acknowledge that all businesses are presented with their own set of unique challenges.</p>
            </div>

                <div className="relative flex-1 flex justify-center w-full md:w-3/4 sm:w-4/5 items-center">
                    <div className="absolute top-0 bottom-0 left-1/2 transform -translate-x-1/2 w-full h-full pointer-events-none">
                        <svg width="100%" height="100%">
                            <line
                                x1="50%"
                                y1="95%"
                                x2="50%"
                                y2="0%"
                                stroke="gray"
                                //strokeDasharray="5, 5"
                                strokeWidth="2"
                                //className="animate-dash"
                            />
                        </svg>
                    </div>

                    {/* Timeline Items */}
                    <div className="flex flex-col items-center justify-between min-h-1/5 max-h-1/4 w-full ">
                        {/* Item 1 */}
                        <div className="flex w-full items-center py-4 lg:py-6 2xl:py-12">
                            <div className="w-1/2 pl-2 pr-8 lg:pr-12 flex justify-end">
                                <div className="text-white p-2 sm:p-4 rounded-lg shadow-lg max-w-xs md:max-w-sm bg-[#1d1c28] border border-gray-500">
                                    <h3 className="font-bold text-base md:text-lg">Discovery</h3>
                                    <p className="text-gray-200 text-xs md:text-base">Exploration of the client's needs and challenges.</p>
                                </div>
                            </div>
                                <div className="left-1/2 -translate-x-1/2 rounded-full p-2 bg-slate-900 shadow border border-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#e5e7eb" className="size-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 18v-5.25m0 0a6.01 6.01 0 0 0 1.5-.189m-1.5.189a6.01 6.01 0 0 1-1.5-.189m3.75 7.478a12.06 12.06 0 0 1-4.5 0m3.75 2.383a14.406 14.406 0 0 1-3 0M14.25 18v-.192c0-.983.658-1.823 1.508-2.316a7.5 7.5 0 1 0-7.517 0c.85.493 1.509 1.333 1.509 2.316V18" />
                                    </svg>
                                </div>
                        </div>

                        {/* Item 2 */}
                        <div className="flex w-full items-center py-4 lg:py-6 2xl:py-12">
                            <div className="w-1/2 flex justify-center items-center">
                                <div className="absolute left-1/2 transform -translate-x-1/2 bg-slate-900 rounded-full p-2 shadow border border-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#e5e7eb" className="size-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="m21 7.5-2.25-1.313M21 7.5v2.25m0-2.25-2.25 1.313M3 7.5l2.25-1.313M3 7.5l2.25 1.313M3 7.5v2.25m9 3 2.25-1.313M12 12.75l-2.25-1.313M12 12.75V15m0 6.75 2.25-1.313M12 21.75V19.5m0 2.25-2.25-1.313m0-16.875L12 2.25l2.25 1.313M21 14.25v2.25l-2.25 1.313m-13.5 0L3 16.5v-2.25" />
                                    </svg>
                                </div>
                            </div>
                            <div className="w-1/2 pr-2 pl-8 lg:pl-12 flex justify-start">
                                <div className="text-white p-4 rounded-lg shadow-lg max-w-xs md:max-w-sm bg-[#1d1c28] border border-gray-500">
                                    <h3 className="font-bold text-base md:text-lg">Design</h3>
                                    <p className="text-gray-200 text-xs md:text-base">Develop customized plan to meet client's goal.</p>
                                </div>
                            </div>
                        </div>

                        {/* Item 3 */}
                        <div className="flex w-full items-center py-4 lg:py-6 2xl:py-12">
                            <div className="w-1/2 pl-2 pr-8 lg:pr-12 flex justify-end">
                                <div className="text-white p-4 rounded-lg shadow-lg max-w-xs md:max-w-sm bg-[#1d1c28] border border-gray-500">
                                    <h3 className="font-bold text-base md:text-lg">Implementation</h3>
                                    <p className="text-gray-200 text-xs md:text-base">Seamless integration into client's architecture.</p>
                                </div>
                            </div>
                            <div className="left-1/2 transform -translate-x-1/2 bg-slate-900 rounded-full p-2 shadow border border-gray-200">
                                <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#e5e7eb" className="size-8">
                                    <path strokeLinecap="round" strokeLinejoin="round" d="M8.25 15 12 18.75 15.75 15m-7.5-6L12 5.25 15.75 9" />
                                </svg>
                            </div>
                        </div>

                        {/* Item 4 */}
                        <div className="flex w-full items-center py-4 lg:py-6 2xl:py-12">
                            <div className="w-1/2 flex justify-center items-center">
                                <div className="absolute left-1/2 transform -translate-x-1/2 bg-slate-900 rounded-full p-2 shadow border border-gray-200">
                                    <svg xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" strokeWidth={1.5} stroke="#e5e7eb" className="size-8">
                                        <path strokeLinecap="round" strokeLinejoin="round" d="M12 21a9.004 9.004 0 0 0 8.716-6.747M12 21a9.004 9.004 0 0 1-8.716-6.747M12 21c2.485 0 4.5-4.03 4.5-9S14.485 3 12 3m0 18c-2.485 0-4.5-4.03-4.5-9S9.515 3 12 3m0 0a8.997 8.997 0 0 1 7.843 4.582M12 3a8.997 8.997 0 0 0-7.843 4.582m15.686 0A11.953 11.953 0 0 1 12 10.5c-2.998 0-5.74-1.1-7.843-2.918m15.686 0A8.959 8.959 0 0 1 21 12c0 .778-.099 1.533-.284 2.253m0 0A17.919 17.919 0 0 1 12 16.5c-3.162 0-6.133-.815-8.716-2.247m0 0A9.015 9.015 0 0 1 3 12c0-1.605.42-3.113 1.157-4.418" />
                                    </svg>
                                </div>
                            </div>
                            <div className="w-1/2 pr-2 pl-8 lg:pl-12 flex justify-start">
                                <div className="text-white p-4 rounded-lg shadow-lg max-w-xs md:max-w-sm bg-[#1d1c28] border border-gray-500">
                                    <h3 className="font-bold text-base md:text-lg">Optimization</h3>
                                    <p className="text-gray-200 text-xs md:text-base">Continuous improvement to enhance product effectiveness.</p>
                                </div>
                            </div>
                            </div>
                    </div>

            </div>
        </div>
        






    );
}



export default Process;