import React, { useState } from 'react';

function ContactUs() {
    const [formData, setFormData] = useState({
        firstName: '',
        lastName: '',
        email: '',
        message: '',
    });

    const [emailError, setEmailError] = useState(''); // State to track email validation errors
    const [messageError, setMessageError] = useState(''); // State to track message validation errors
    const [formMessage, setFormMessage] = useState(''); // State to display success or error messages
    const [formMessageType, setFormMessageType] = useState(''); // Type of message: 'success' or 'error'

    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData({ ...formData, [name]: value });

        if (name === 'email') {
            setEmailError(''); // Clear email error when user starts typing
        }

        if (name === 'message') {
            setMessageError(''); // Clear message error when user starts typing
        }

        setFormMessage(''); // Clear any previous form messages
    };

    const validateEmail = (email) => {
        const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
        return emailRegex.test(email);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validate email
        if (!formData.email) {
            setEmailError('Email is required.');
            return;
        }

        if (!validateEmail(formData.email)) {
            setEmailError('Please enter a valid email address.');
            return;
        }

        // Validate message
        if (!formData.message) {
            setMessageError('Message is required.');
            return;
        }

        try {
            const response = await fetch('https://qe3erz6die76kgzfqhk7ilv7ie0btevm.lambda-url.us-east-2.on.aws/', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify(formData),
            });

            if (response.ok) {
                setFormMessageType('success');
                setFormMessage('Message sent successfully!');
                setFormData({ firstName: '', lastName: '', email: '', message: '' });
            } else {
                setFormMessageType('error');
                setFormMessage('Failed to send message. Please try again.');
            }
        } catch (error) {
            console.error('Error:', error);
            setFormMessageType('error');
            setFormMessage('An error occurred. Please try again.');
        }
    };

    return (
        <div className='mx-auto text-center flex flex-col items-center rounded-2xl max-w-[600px] px-10 pb-10' id='contact'>
            <h1 className='md:text-5xl sm:text-4xl text-3xl font-bold pt-4 md:pt-10 text-indigo-300 antialiased pb-8'>Contact Us</h1>
            <div className='shadow-xl shadow-black border rounded-lg border-indigo-300 px-10 py-4 bg-[#1d1c28] w-full'>
                <form className="w-full pt-10" onSubmit={handleSubmit}>
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/4">
                            <label className="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-first-name">
                                First Name
                            </label>
                        </div>
                        <div className="md:w-3/4">
                            <input
                                className="appearance-none bg-transparent border-b border-gray-500 invalid:border-red-500 w-full text-sm text-gray-200 mr-3 py-1 px-2 leading-tight focus:bg-[#0a0a16]"
                                type="text"
                                name="firstName"
                                value={formData.firstName}
                                onChange={handleChange}
                                placeholder=""
                                aria-label="First name"
                            />
                        </div>
                    </div>
                    <div className="md:flex md:items-center mb-6">
                        <div className="md:w-1/4">
                            <label className="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-last-name">
                                Last Name
                            </label>
                        </div>
                        <div className="md:w-3/4">
                            <input
                                className="appearance-none bg-transparent border-b border-gray-500 w-full text-sm text-gray-200 mr-3 py-1 px-2 leading-tight focus:bg-[#0a0a16]"
                                type="text"
                                name="lastName"
                                value={formData.lastName}
                                onChange={handleChange}
                                placeholder=""
                                aria-label="Last name"
                            />
                        </div>
                    </div>
                    <div className="md:flex md:items-baseline mb-6">
                        <div className="md:w-1/4">
                            <label className="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-email">
                                Email
                            </label>
                        </div>
                        <div className="md:w-3/4">
                            <input
                                className="appearance-none bg-transparent border-b border-gray-500 w-full text-sm text-gray-200 mr-3 py-1 px-2 leading-tight focus:bg-[#0a0a16]"
                                type="email"
                                name="email"
                                value={formData.email}
                                onChange={handleChange}
                                placeholder=""
                                aria-label="Email"
                            />
                            {emailError && <p className="text-red-500 text-sm mt-1">{emailError}</p>}
                        </div>
                    </div>
                    <div className="md:flex mb-6 align-text-top">
                        <div className="md:w-1/4">
                            <label className="block text-white font-bold md:text-md md:text-right mb-1 md:mb-0 pr-4" htmlFor="inline-message">
                                Message
                            </label>
                        </div>
                        <div className="md:w-3/4">
                            <div className="relative w-full min-w-[200px]">
                                <textarea
                                    className="peer h-full min-h-[100px] w-full text-sm resize-none rounded-[7px] border border-gray-500 bg-transparent px-3 py-2.5 font-normal text-gray-200 focus:bg-[#0a0a16]"
                                    name="message"
                                    value={formData.message}
                                    onChange={handleChange}
                                    placeholder=" "
                                ></textarea>
                                {messageError && <p className="text-red-500 text-sm mt-1">{messageError}</p>}
                            </div>
                        </div>
                    </div>
                    {formMessage && (
                        <p
                            className={`text-sm mt-4 ${
                                formMessageType === 'success' ? 'text-green-500' : 'text-red-500'
                            }`}
                        >
                            {formMessage}
                        </p>
                    )}
                    <div className="flex flex-col items-center py-2">
                        <button
                            className="shadow hover:bg-[#0a0a16] bg-indigo-300 hover:text-white text-black focus:shadow-outline focus:outline-none font-bold py-2 px-4 rounded w-full"
                            type="submit"
                        >
                            Send Message
                        </button>
                    </div>
                </form>
            </div>
        </div>
    );
}

export default ContactUs;